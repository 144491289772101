import { useEffect, useState } from "react";
import OfferItem from "../offer-item/OfferItem.component";
import {
  CampaignType,
  Offer,
  OffersIdSlots,
  PlacementStage,
  QueryPostOfferView,
  Template,
} from "../../../../types/global.types";
import { TemplateOptionSettings } from "../../../../types/global.types";
import { postOfferView } from "../../../../services/offers/offers.service";

interface Props {
  displayOnly: boolean;
  settings: TemplateOptionSettings;
  campaign: CampaignType;
  offersCount: string;
  offers: Offer[];
  settingsTemplate: Template;
  campaignId: string;
  placementId: string;
  placementName: string;
  isInline?: boolean;
  isSeen: boolean;
  preEmail: string | undefined;
  setPreEmail?: (email: string) => void;
  openSingle: (
    o: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number
  ) => void;
  onAdd: (offer: Offer, value: boolean, slot: number) => void;
}

const GridOffers = ({
  isSeen,
  settings,
  preEmail,
  setPreEmail,
  campaign,
  offers,
  displayOnly,
  campaignId,
  placementId,
  placementName,
  settingsTemplate,
  openSingle,
  onAdd,
}: Props) => {
  const [eventOfferIds, setEventOfferIds] = useState<OffersIdSlots[]>([]);

  const setDefaultOfferImpressionEvent = async (offers: OffersIdSlots[]) => {
    if (eventOfferIds.length < offers.length) {
      const data: QueryPostOfferView = {
        offers,
        campaignId,
        campaignName: campaign.name || "",
        companyId: campaign.company_Id,
        placementId,
        placementName,
        pageUrl: "",
        templateId: settingsTemplate.identifier,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      };

      await postOfferView(data);
    }
  };

  //set event for default active slide offers
  useEffect(() => {
    if (isSeen) {
      const _ids = offers.map((offer: Offer, index: number) => ({
        id: offer.id,
        slot: index + 1,
      }));
      setDefaultOfferImpressionEvent(_ids);
      setEventOfferIds(_ids);
    }
    // eslint-disable-next-line
  }, [isSeen]);

  return (
    <div
      className="grid-wrapper grid"
      id="grid-container"
      style={{
        gridTemplateColumns: `repeat(${settings.mainLayout.offersNumber}, 1fr)`,
      }}
    >
      {offers?.map((o: Offer, i: number) => {
        return (
          <OfferItem
            key={o.id}
            offer={o}
            slot={i + 1}
            eventOfferIds={eventOfferIds}
            settings={settings}
            isAdded={o.isAdded || false}
            settingsTemplate={settingsTemplate}
            onAdd={onAdd}
            displayOnly={displayOnly}
            campaign={campaign}
            retailerId={campaign?.company_Id}
            campaignId={campaignId}
            placementId={placementId}
            placementName={placementName}
            openSingle={openSingle}
            setPreEmail={setPreEmail}
            preEmail={preEmail}
            hasVoucher={offers.some(
              (offer) => offer.voucher && offer.voucher.voucherType
            )}
            otherSettings={settings.otherSettings}
          />
        );
      })}
    </div>
  );
};

export default GridOffers;
