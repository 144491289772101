import { useEffect, useMemo, useRef, useState } from "react";
import "./templateFilters.scss";
import {
  CampaignType,
  Offer,
  OfferCategory,
  TemplateOptionSettings,
} from "../../../../../types/global.types";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types"; // Import Swiper type for TypeScript

export interface Props {
  settings: TemplateOptionSettings | undefined;
  campaign: CampaignType;
  setFilteredList: React.Dispatch<React.SetStateAction<Offer[]>>;
}

const TemplateFilters = ({ campaign, settings, setFilteredList }: Props) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [offerHostType, setOfferHostType] = useState<string>("");
  const [offerFullList, setOfferFullList] = useState<Offer[]>(campaign.offers);
  const [offerFilteredList, setOfferFilteredList] = useState<Offer[]>(
    campaign.offers
  );

  const refNavPrev = useRef<HTMLDivElement | null>(null);
  const refNavNext = useRef<HTMLDivElement | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const offersCategories = useMemo(() => {
    const cats: string[] = [];

    offerFullList.forEach((o) => {
      o.categories.forEach((cat: OfferCategory) => {
        !cats.includes(cat.name) && cats.push(cat.name);
      });
    });

    return cats;
    // eslint-disable-next-line
  }, [offerFullList]);

  useEffect(() => {
    setFilteredList(offerFilteredList);
    // eslint-disable-next-line
  }, [offerFilteredList]);

  useEffect(() => {
    const _offerFilteredList: Offer[] = [];
    const _offerFullList: Offer[] = [];
    campaign.offers.forEach((o: Offer) => {
      if (offerHostType) {
        setSelectedCategory("");
        if (
          (!o.offerSettings.isB2C && offerHostType === "trade") ||
          (o.offerSettings.isB2C && offerHostType === "personal") ||
          offerHostType === "all"
        ) {
          _offerFullList.push(o);
          _offerFilteredList.push(o);
        }
        setOfferFilteredList(_offerFilteredList);
        setOfferFullList(_offerFullList);
      }
    });
    // eslint-disable-next-line
  }, [offerHostType]);

  useEffect(() => {
    const _offerFilteredList: Offer[] = [];
    offerFullList.forEach((o) => {
      if (selectedCategory) {
        o.categories.forEach((cat: OfferCategory) => {
          cat.name === selectedCategory && _offerFilteredList.push(o);
        });
      } else {
        _offerFilteredList.push(o);
      }
      setOfferFilteredList(_offerFilteredList);
    });
    // eslint-disable-next-line
  }, [selectedCategory]);

  useEffect(() => {
    const _tradeOffers = [];
    const _personalOffers = [];

    campaign.offers.forEach((o) => {
      o.offerSettings.isB2C ? _personalOffers.push(o) : _tradeOffers.push(o);
    });

    _tradeOffers.length > 0 &&
      _personalOffers.length === 0 &&
      setOfferHostType("trade");
    _personalOffers.length > 0 &&
      _tradeOffers.length === 0 &&
      setOfferHostType("personal");
  }, [campaign.offers]);

  const handleInit = (swiper: SwiperType) => {
    // Save the swiper instance to the ref
    if (swiperRef) {
      swiperRef.current = swiper;
      checkSwipeable(swiper);
    }
  };

  const handleUpdate = (swiper: SwiperType) => {
    checkSwipeable(swiper);
  };

  const checkSwipeable = (swiper: SwiperType) => {
    const swiperContainer = swiper.el; // Swiper container element

    if (swiper.isLocked) {
      // When Swiper is locked (not swipeable)
      swiperContainer.classList.add("no-swipe");
    } else {
      // When Swiper is swipeable
      swiperContainer.classList.remove("no-swipe");
    }
  };

  return (
    <>
      {!offerHostType ? (
        <div className="preview-f-closedfilters">
          {!!campaign.offers.find((o: Offer) => o.offerSettings.isB2C) && (
            <div
              className="preview-f-button"
              onClick={() => setOfferHostType("personal")}
              style={{
                color: settings?.filters?.activeColor,
                backgroundColor: settings?.filters?.backgroundActiveColor,
                borderColor: settings?.filters?.borderActiveColor,
                borderRadius: `${
                  typeof settings?.mainLayout?.borderRadius === "number"
                    ? settings?.mainLayout?.borderRadius
                    : 10
                }px`,
              }}
            >
              {settings?.filters?.showMorePersonalOffers ||
                "Show more personal offers"}
            </div>
          )}
          {!!campaign.offers.find((o: Offer) => !o.offerSettings.isB2C) && (
            <div
              className="preview-f-button"
              onClick={() => setOfferHostType("trade")}
              style={{
                color: settings?.filters?.activeColor,
                backgroundColor: settings?.filters?.backgroundActiveColor,
                borderColor: settings?.filters?.borderActiveColor,
                borderRadius: `${
                  typeof settings?.mainLayout?.borderRadius === "number"
                    ? settings?.mainLayout?.borderRadius
                    : 10
                }px`,
              }}
            >
              {settings?.filters?.showMoreTradeOffers ||
                "Show more trade offers"}
            </div>
          )}
        </div>
      ) : (
        <div className="preview-f-openfilters">
          {!!campaign.offers.find((o: Offer) => !o.offerSettings.isB2C) &&
            !!campaign.offers.find((o: Offer) => o.offerSettings.isB2C) && (
              <div className="preview-f-filter">
                <ul
                  style={{
                    borderColor: settings?.filters?.borderColor || "#939393",
                    borderRadius: `${
                      typeof settings?.mainLayout?.borderRadius === "number"
                        ? settings?.mainLayout?.borderRadius
                        : 10
                    }px`,
                  }}
                >
                  <li
                    onClick={() => setOfferHostType("personal")}
                    className={`${
                      settings?.filters?.borderUnderline
                        ? "border-underline"
                        : ""
                    }`}
                    style={{
                      color:
                        offerHostType === "personal"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : settings?.filters?.textColor || "#939393",
                      backgroundColor:
                        offerHostType === "personal"
                          ? settings?.filters?.backgroundActiveColor ||
                            "#e5eef5"
                          : settings?.filters?.backgroundColor || "#e5eef5",
                      borderColor:
                        offerHostType === "personal"
                          ? settings?.filters?.borderActiveColor || "#0053a0"
                          : settings?.filters?.borderColor || "#0053a0",
                      borderRadius: `${
                        typeof settings?.mainLayout?.borderRadius === "number"
                          ? settings?.mainLayout?.borderRadius
                          : 10
                      }px`,
                    }}
                  >
                    {settings?.filters?.personalOffers || "Personal offers"}
                  </li>
                  <li
                    onClick={() => setOfferHostType("trade")}
                    className={`${
                      settings?.filters?.borderUnderline
                        ? "border-underline"
                        : ""
                    }`}
                    style={{
                      color:
                        offerHostType === "trade"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : settings?.filters?.textColor || "#939393",
                      backgroundColor:
                        offerHostType === "trade"
                          ? settings?.filters?.backgroundActiveColor ||
                            "#e5eef5"
                          : settings?.filters?.backgroundColor || "#e5eef5",
                      borderColor:
                        offerHostType === "trade"
                          ? settings?.filters?.borderActiveColor || "#0053a0"
                          : settings?.filters?.borderColor || "#0053a0",
                      borderRadius: `${
                        typeof settings?.mainLayout?.borderRadius === "number"
                          ? settings?.mainLayout?.borderRadius
                          : 10
                      }px`,
                    }}
                  >
                    {settings?.filters?.tradeOffers || "Trade offers"}
                  </li>
                  <li
                    onClick={() => setOfferHostType("all")}
                    className={`${
                      settings?.filters?.borderUnderline
                        ? "border-underline"
                        : ""
                    }`}
                    style={{
                      color:
                        offerHostType === "all"
                          ? settings?.filters?.activeColor || "#0053a0"
                          : settings?.filters?.textColor || "#939393",
                      backgroundColor:
                        offerHostType === "all"
                          ? settings?.filters?.backgroundActiveColor ||
                            "#e5eef5"
                          : settings?.filters?.backgroundColor || "#e5eef5",
                      borderColor:
                        offerHostType === "all"
                          ? settings?.filters?.borderActiveColor || "#0053a0"
                          : settings?.filters?.borderColor || "#0053a0",
                      borderRadius: `${
                        typeof settings?.mainLayout?.borderRadius === "number"
                          ? settings?.mainLayout?.borderRadius
                          : 10
                      }px`,
                    }}
                  >
                    {settings?.filters?.allOffers || "All"}
                  </li>
                </ul>
              </div>
            )}
          <div className="preview-f-filter">
            <ul
              className="swiper-filters-container"
              style={{
                borderColor: settings?.filters?.borderColor || "#939393",
                borderRadius: `${
                  typeof settings?.mainLayout?.borderRadius === "number"
                    ? settings?.mainLayout?.borderRadius
                    : 10
                }px`,
              }}
            >
              <Swiper
                className={`swiper-container swiper-filters`}
                modules={[Navigation]}
                slidesPerView={"auto"}
                slidesPerGroupAuto={true}
                onInit={handleInit}
                onUpdate={handleUpdate}
                loop={false}
                spaceBetween={0}
                navigation={{
                  prevEl: refNavPrev.current,
                  nextEl: refNavNext.current,
                }}
              >
                <div className="swiper-button-prev" ref={refNavPrev}>
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="18.6094"
                      y="23"
                      width="8.6192"
                      height="1.72384"
                      rx="0.86192"
                      transform="rotate(-135 18.6094 23)"
                      fill={settings?.filters?.textColor || "#939393"}
                    />
                    <rect
                      x="20.3135"
                      y="12.2188"
                      width="8.6192"
                      height="1.72384"
                      rx="0.86192"
                      transform="rotate(135 20.3135 12.2188)"
                      fill={settings?.filters?.textColor || "#939393"}
                    />
                  </svg>
                </div>

                <div className="swiper-button-next" ref={refNavNext}>
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="15.3906"
                      y="11"
                      width="8.6192"
                      height="1.72384"
                      rx="0.86192"
                      transform="rotate(45 15.3906 11)"
                      fill={settings?.filters?.textColor || "#939393"}
                    />
                    <rect
                      x="14.1719"
                      y="22.2656"
                      width="8.6192"
                      height="1.72384"
                      rx="0.86192"
                      transform="rotate(-45 14.1719 22.2656)"
                      fill={settings?.filters?.textColor || "#939393"}
                    />
                  </svg>
                </div>

                {offersCategories.length > 0 && (
                  <SwiperSlide key="all" style={{ width: "auto" }}>
                    <li
                      onClick={() => {
                        setOfferHostType("all");
                        setSelectedCategory("");
                      }}
                      className={`${
                        settings?.filters?.borderUnderline
                          ? "border-underline"
                          : ""
                      }`}
                      style={{
                        color: !selectedCategory
                          ? settings?.filters?.activeColor || "#0053a0"
                          : settings?.filters?.textColor || "#939393",
                        backgroundColor: !selectedCategory
                          ? settings?.filters?.backgroundActiveColor ||
                            "#e5eef5"
                          : settings?.filters?.backgroundColor || "#e5eef5",
                        borderColor: !selectedCategory
                          ? settings?.filters?.borderActiveColor || "#0053a0"
                          : settings?.filters?.borderColor || "#0053a0",
                        borderRadius: `${
                          typeof settings?.mainLayout?.borderRadius === "number"
                            ? settings?.mainLayout?.borderRadius
                            : 10
                        }px`,
                      }}
                    >
                      All categories
                    </li>
                  </SwiperSlide>
                )}
                {offersCategories.map((category) => {
                  return (
                    <SwiperSlide key={category} style={{ width: "auto" }}>
                      <li
                        onClick={() =>
                          setSelectedCategory(
                            category === selectedCategory ? "" : category
                          )
                        }
                        className={`${
                          settings?.filters?.borderUnderline
                            ? "border-underline"
                            : ""
                        }`}
                        style={{
                          color:
                            category === selectedCategory
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            category === selectedCategory
                              ? settings?.filters?.backgroundActiveColor ||
                                "#e5eef5"
                              : settings?.filters?.backgroundColor || "#e5eef5",
                          borderColor:
                            category === selectedCategory
                              ? settings?.filters?.borderActiveColor ||
                                "#0053a0"
                              : settings?.filters?.borderColor || "#0053a0",
                          borderRadius: `${
                            typeof settings?.mainLayout?.borderRadius ===
                            "number"
                              ? settings?.mainLayout?.borderRadius
                              : 10
                          }px`,
                        }}
                      >
                        {category}
                      </li>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateFilters;
