import React, { useCallback, useEffect, useState } from "react";
import PreviewPopupC13 from "../components/templates/templatec13/PreviewPopupC13.component";
import PreviewEmbeddedCompact from "../components/templates/templateEmbeddedCompact/PreviewEmbeddedCompact.component";
import PreviewEmbeddedS from "../components/templates/templateEs/PreviewEmbeddedS.component";
import PreviewEmbeddedSTop from "../components/templates/templateEsTop/PreviewEmbeddedSTop.component";
import PreviewPopupFilters48 from "../components/templates/templateFilters48/PreviewPopupFilters48.component";
import PreviewPopupFiltersMobile8 from "../components/templates/templateFiltersMobile8/PreviewPopupFilterMobile8.component";
import PreviewPopupN13 from "../components/templates/templateN13/PreviewPopupN13.component";
import PreviewPopupN36 from "../components/templates/templateN36/PreviewPopupN36.component";
import PreviewNL from "../components/templates/templateNL/PreviewNL.component";
import PreviewNLH from "../components/templates/templateNLH/PreviewNLH.component";
import { getTrackingEvent, postEmail } from "../services/offers/offers.service";
import {
  CampaignType,
  LayoutClass,
  LayoutDeviceSettings,
  Offer,
  PlacementStage,
  QueryParams,
  QueryPostEmail,
} from "../types/global.types";
import PreviewEmbeddedDME6 from "../components/templates/templateDME6/PreviewEmbeddedDME6.component";
import PreviewPopupFeature from "../components/templates/templateFeature/PreviewPopupFeature.component";
import PreviewPopupFeatureMobile from "../components/templates/templateFeatureMobile/PreviewPopupFeatureMobile.component";
import PreviewPopupSuper from "../components/templates/templateSuper/PreviewPopupSuper.component";
import PreviewPopupCompact from "../components/templates/templateCompactV2/PreviewPopupCompactV2.component";
import PreviewEmbeddedSuper from "../components/templates/templateEmbeddedSuper/PreviewEmbeddedSuper.component";

function TemplatePage() {
  const [params, setParams] = useState<QueryParams>();
  const [settings, setSettings] = useState<LayoutDeviceSettings>();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<CampaignType>();
  const [placementId, setPlacementId] = useState<string>();
  const [placementName, setPlacementName] = useState<string>("");
  const [first, setFirst] = useState<boolean>(true);

  const [selectedOffers, setSelectedOffers] = useState<string[]>([]);
  const [offerSlot, setOfferSlot] = useState<number>(0);
  const [singleOffer, setSingleOffer] = useState<Offer | undefined>();
  const [preEmail, setPreEmail] = useState<string>();
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [emailPostParams, setEmailPostParams] = useState<QueryPostEmail>();
  const [sessionId, setSessionId] = useState<string>("");
  const [isSave, setSave] = useState<boolean>();
  const [isShare, setShare] = useState<boolean>(false);
  const [isSeen, setSeen] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (Object.keys(event.data).includes("options")) {
        setSettings(event.data?.options);
      }
      if (Object.keys(event.data).includes("seen")) {
        setSeen(event.data?.seen);
      }
      if (Object.keys(event.data).includes("isSave")) {
        setSave(event.data?.isSave);
      }
      if (Object.keys(event.data).includes("isShare")) {
        setShare(event.data?.isShare);
      }
      if (Object.keys(event.data).includes("open")) {
        setOpen(event.data?.open);
      }
      if (Object.keys(event.data).includes("campaign")) {
        setCampaign(event.data?.campaign);
      }
      if (Object.keys(event.data).includes("queryParams")) {
        setParams(event?.data.queryParams);
      }
      if (Object.keys(event.data).includes("first")) {
        setFirst(event?.data.first);
      }
      if (Object.keys(event.data).includes("selectedOffers")) {
        setSelectedOffers(event?.data.selectedOffers);
      }
      if (Object.keys(event.data).includes("sessionId")) {
        setSessionId(event?.data.sessionId);
      }
      if (Object.keys(event.data).includes("placementId")) {
        setPlacementId(event?.data.placementId);
      }
      if (Object.keys(event.data).includes("placementName")) {
        setPlacementName(event?.data.placementName);
      }

      if (Object.keys(event.data).includes("placementName")) {
        setPlacementName(event?.data.placementName);
      }

      if (Object.keys(event.data).includes("postEmail")) {
        setEmailPostParams(event?.data.postEmail);
      }

      if (Object.keys(event.data).includes("slot")) {
        setOfferSlot(event?.data.slot);
      }

      if (Object.keys(event.data).includes("offer")) {
        setSingleOffer(event?.data.offer);
      }

      if (Object.keys(event.data).includes("isEmail")) {
        setIsEmail(event?.data.isEmail);
      }

      if (Object.keys(event.data).includes("preEmail")) {
        setPreEmail(event?.data.preEmail);
      }

      if (Object.keys(event.data).includes("exit")) {
        setParams(event?.data.exit.queryParams);
        setPlacementName(event?.data.exit.placementName);
        setPlacementId(event?.data.exit.placementId);
        setSettings(event?.data.exit.settings);
        setOpen(true);
      }

      if (Object.keys(event.data).includes("extra")) {
        setSettings(event?.data.extra.settings);
        setParams(event?.data.extra.queryParams);
        setPlacementName(event?.data.extra.placementName);
        setPlacementId(event?.data.extra.placementId);
        setSingleOffer(event?.data.extra.offer);
        setOpen(event.data?.extra.open);
        setCampaign(event.data.extra?.campaign);
        setOfferSlot(event.data?.extra.slot);
      }
    });
  }, []);

  useEffect(() => {
    const isVisible = campaign?.placementSettings[0]?.isVisible;
    if (isSeen && isOpen) {
      if (isVisible) {
        getTrackingEvent({
          event: "ontemplateseen",
          retailerId: campaign?.company_Id,
          campaignId: campaign?.id,
          placementId,
          placementName,
          tid: settings?.template.identifier,
          pltype: campaign?.placementSettings[0]?.displayOnly
            ? PlacementStage.pre
            : PlacementStage.post,
        });
      }
    }
    // eslint-disable-next-line
  }, [isSeen, isOpen]);

  useEffect(() => {
    return () => window.removeEventListener("message", () => {});
  }, []);

  const postEmailMethod = async (post: QueryPostEmail) => {
    const response = await postEmail(post);
    if (response?.data?.status) {
      window.top?.postMessage(
        { event: "clearPostEmailStorage", load: "" },
        "*"
      );
    }
  };

  useEffect(() => {
    if (emailPostParams?.companyId && settings?.template) {
      emailPostParams.contactDetails.length > 0 &&
        setPreEmail(emailPostParams.contactDetails[0].email);
      postEmailMethod({
        ...emailPostParams,
        templateId: settings?.template.identifier,
        pltype: PlacementStage.pre,
      });
    }
    // eslint-disable-next-line
  }, [emailPostParams]);

  const getTemplateComponent = useCallback(() => {
    let elm: React.JSX.Element = <></>;
    if (
      settings &&
      placementId &&
      params &&
      campaign?.id &&
      settings.settings &&
      campaign
    ) {
      const isVisible = campaign.placementSettings[0].isVisible;

      if (!isVisible) {
        window.top?.postMessage({ event: "closeMainIframe", load: "" }, "*");
        return <></>;
      }

      switch (settings?.template.identifier) {
        case "template-dlp1pre":
        case "template-dlp2pre":
        case "template-dlp3pre":
        case "template-dlp1post":
        case "template-dlp2post":
        case "template-dlp3post":
          elm = (
            <PreviewPopupN13
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              email={preEmail}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
            />
          );
          break;

        case "template-dlp3+pre":
        case "template-dlp3+post":
          elm = (
            <PreviewPopupN36
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              first={first}
              email={preEmail}
              defaultOpen={isOpen}
              setOpen={setOpen}
              defaultSix={false}
            />
          );
          break;

        case "template-dmp8pre":
        case "template-dmp8post":
          elm = (
            <PreviewPopupFilters48
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              email={preEmail}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
              defaultSix={true}
            />
          );
          break;

        case "template-msp8pre":
        case "template-msp8post":
          elm = (
            <PreviewPopupFiltersMobile8
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              email={preEmail}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
            />
          );
          break;

        case "template-dlp6pre":
        case "template-dlp6post":
          elm = (
            <PreviewPopupN36
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              email={preEmail}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
              defaultSix={true}
            />
          );
          break;

        case "template-msp3pre":
        case "template-msp3post":
        case "template-dsp3post":
        case "template-dsp3pre":
          elm = (
            <PreviewPopupC13
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              email={preEmail}
              selectedOffers={selectedOffers}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
            />
          );

          break;
        case "template-super-pre":
        case "template-super-post":
          elm = (
            <PreviewPopupSuper
              slot={offerSlot}
              params={params}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              email={preEmail}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
              defaultSix={true}
            />
          );
          break;
        case "template-esuper-pre":
        case "template-esuper-post":
          elm = (
            <PreviewEmbeddedSuper
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              layoutSettings={settings}
              placementId={placementId}
              placementName={placementName}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              singleOffer={singleOffer}
              isSave={isSave}
              isShare={isShare}
              isEmail={isEmail}
              email={preEmail}
              setIsEmail={setIsEmail}
              setSingle={setSingleOffer}
            />
          );
          break;
        case "template-feature-pre":
        case "template-feature-post":
          elm = (
            <PreviewPopupFeature
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              email={preEmail}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
              defaultSix={true}
            />
          );
          break;

        case "template-compact-v2-pre":
        case "template-compact-v2-post":
          elm = (
            <PreviewPopupCompact
              slot={offerSlot}
              params={params}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              email={preEmail}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
            />
          );
          break;

        case "template-feature-mobile-pre":
        case "template-feature-mobile-post":
          elm = (
            <PreviewPopupFeatureMobile
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              type={settings?.type || LayoutClass.action}
              settings={settings.settings}
              settingsTemplate={settings.template}
              placementId={placementId}
              placementName={placementName}
              buttonShape={settings.button.shape}
              campaignId={campaign.id}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              email={preEmail}
              first={first}
              defaultOpen={isOpen}
              setOpen={setOpen}
              defaultSix={true}
            />
          );
          break;

        case "template-Es":
          elm = (
            <PreviewEmbeddedS
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              layoutSettings={settings}
              placementId={placementId}
              placementName={placementName}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              singleOffer={singleOffer}
              isSave={isSave}
              isShare={isShare}
              isEmail={isEmail}
              email={preEmail}
              setIsEmail={setIsEmail}
              setSingle={setSingleOffer}
            />
          );
          break;

        case "template-Ec1":
        case "template-Ec2":
        case "template-Ec3":
          elm = (
            <PreviewEmbeddedCompact
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              layoutSettings={settings}
              placementId={placementId}
              placementName={placementName}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              singleOffer={singleOffer}
              isSave={isSave}
              isShare={isShare}
              isEmail={isEmail}
              email={preEmail}
              setIsEmail={setIsEmail}
              setSingle={setSingleOffer}
            />
          );
          break;

        case "template-NL":
          elm = (
            <PreviewNL
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              layoutSettings={settings}
              placementId={placementId}
              placementName={placementName}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              singleOffer={singleOffer}
              isSave={isSave}
              isShare={isShare}
              isEmail={isEmail}
              email={preEmail}
              setIsEmail={setIsEmail}
              setSingle={setSingleOffer}
            />
          );
          break;
        case "template-NLH":
          elm = (
            <PreviewNLH
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              layoutSettings={settings}
              placementId={placementId}
              placementName={placementName}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              singleOffer={singleOffer}
              isSave={isSave}
              isShare={isShare}
              isEmail={isEmail}
              email={preEmail}
              setIsEmail={setIsEmail}
              setSingle={setSingleOffer}
            />
          );
          break;

        case "template-EsTop":
          elm = (
            <PreviewEmbeddedSTop
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              layoutSettings={settings}
              placementId={placementId}
              placementName={placementName}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              singleOffer={singleOffer}
              isSave={isSave}
              isShare={isShare}
              isEmail={isEmail}
              email={preEmail}
              setIsEmail={setIsEmail}
              setSingle={setSingleOffer}
            />
          );
          break;
        case "template-DME6":
          elm = (
            <PreviewEmbeddedDME6
              slot={offerSlot}
              params={params}
              isSeen={isSeen}
              layoutSettings={settings}
              placementId={placementId}
              placementName={placementName}
              campaignRes={campaign}
              selectedOffers={selectedOffers}
              singleOffer={singleOffer}
              isSave={isSave}
              isShare={isShare}
              isEmail={isEmail}
              email={preEmail}
              setIsEmail={setIsEmail}
              setSingle={setSingleOffer}
            />
          );
          break;

        default:
          elm = <></>;
          break;
      }
    }

    return elm;
  }, [
    isOpen,
    campaign,
    placementId,
    first,
    settings,
    params,
    selectedOffers,
    placementName,
    singleOffer,
    offerSlot,
    isEmail,
    preEmail,
    isSave,
    isShare,
    isSeen,
  ]);

  useEffect(() => {
    if (sessionId) {
      sessionStorage.setItem("usersessionid", sessionId);
    }
  }, [sessionId]);

  return getTemplateComponent();
}

export default TemplatePage;
