import { useEffect, useMemo, useState } from "react";
import {
  getCustomVoucher,
  getTrackingEvent,
  postOfferView,
  postTagEmailOffer,
} from "../../../../services/offers/offers.service";
import {
  CampaignType,
  DealLayoutSettings,
  MainLayoutSettings,
  Offer,
  OtherSettingsInterface,
  PlacementStage,
  QueryPostOfferView,
  QuerySaveEmail,
  RedeemBy,
  Template,
  TemplateIcons,
  VoucherType,
} from "../../../../types/global.types";
import { EMAIL_REGEX } from "../../../../utility/regexp";
import offerImageSrc from "./../../../../assets/images/offer-listening.png";
import PreSelect from "../../components/PreSelect/PreSelect";
interface Props {
  offer: Offer;
  slot: number;
  settings: DealLayoutSettings;
  mainSettings: MainLayoutSettings;
  settingsTemplate: Template;
  displayOnly: boolean;
  isAdded: boolean;
  retailerId: string;
  campaignId: string;
  placementId: string;
  placementName: string;
  campaign: CampaignType;
  defaultOpen: boolean;
  preEmail: string | undefined;
  setPreEmail?: (email: string) => void;
  openSingle: (
    o: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number,
    isShare?: boolean
  ) => void;
  onAdd: (offer: Offer, value: boolean, slot: number) => void;
  hasVoucher: boolean;
  offerLength: number;
  otherSettings: OtherSettingsInterface | undefined;
}

const OfferFeatureItem = ({
  offer,
  preEmail,
  setPreEmail,
  slot,
  settings,
  mainSettings,
  campaign,
  settingsTemplate,
  displayOnly,
  retailerId,
  campaignId,
  placementId,
  placementName,
  isAdded,
  openSingle,
  onAdd,
  hasVoucher,
  offerLength,
  otherSettings,
  defaultOpen,
}: Props) => {
  const [email, setEmail] = useState<string | undefined>(preEmail);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(
    preEmail ? true : false
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [emailPopulate, setEmailPopulate] = useState<boolean>(true);
  const [emailSwitch, setEmailSwitch] = useState<boolean>(
    preEmail || campaign.campaignGeneralOptions.automaticallyCheckOptIn
      ? true
      : false
  );
  const [sentClick, setSentClick] = useState<boolean>(false);
  const [copyCode, setCopyCode] = useState<boolean>(false);
  const [getCode, setGetCode] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const [customVoucher, setCustomVoucher] = useState<string>("");
  const [voucherLoading, setVoucherLoading] = useState<boolean>(false);

  const imageUrl = useMemo(() => {
    return offer.media.featured.images.length
      ? offer.media.featured.images[0].filepath
      : offer.imageUrl || offerImageSrc;
  }, [offer]);

  const title = useMemo(() => {
    return offer.media.featured.title
      ? offer.media.featured.title
      : offer.title;
  }, [offer]);

  const description = useMemo(() => {
    return offer.media.featured.description
      ? offer.media.featured.description
      : offer.description;
  }, [offer]);

  const handleAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    if (displayOnly) {
      onAdd(offer, value, slot);
      value && handleOpenEmailInput();
    }
  };

  const setDefaultOfferImpressionEvent = async () => {
    const data: QueryPostOfferView = {
      offers: [{ id: offer.id, slot: offer.slot }],
      campaignId,
      campaignName: campaign.name || "",
      companyId: campaign.company_Id,
      placementId,
      placementName,
      pageUrl: "",
      templateId: settingsTemplate.identifier,
      pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
    };

    await postOfferView(data);
  };

  useEffect(() => {
    if (defaultOpen) {
      setDefaultOfferImpressionEvent();
    }
    // eslint-disable-next-line
  }, [defaultOpen]);

  const handleOpenEmailInput = () => {
    if (
      campaign?.campaignGeneralOptions.showAddToOrder &&
      campaign?.campaignGeneralOptions.emailCaptureOnOffers &&
      campaign?.campaignGeneralOptions.emailCaptureOnAddToOrder
    ) {
      openSingle(offer, false, true, slot);
    }
  };

  const handleGetGift = (event: React.MouseEvent<HTMLElement>, url: string) => {
    if (offer?.voucher?.voucherType === VoucherType.CUSTOM && !getCode) {
      return;
    }

    if (
      event.target instanceof HTMLElement &&
      !event.target.classList.contains("super-item-info-icon") &&
      !event.target.classList.contains("copy-box") &&
      !event.target.classList.contains("non-email-link") &&
      !displayOnly &&
      offer.offerSettings.redeemBy !== RedeemBy.email
    ) {
      window.open(
        url + `&tid=${settingsTemplate.identifier}&slot=${slot}`,
        "_blank"
      );
    }
  };

  const handleClickInfo = (event: React.MouseEvent) => {
    event.preventDefault();
    openSingle(offer, false, false, slot);
    getTrackingEvent({
      event: "onreadmoreclick",
      offerId: offer.id,
      retailerId,
      campaignId,
      placementId,
      placementName,
      tid: settingsTemplate.identifier,
      slot,
      pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
    });
  };

  const handleClickShare = (event: React.MouseEvent) => {
    event.preventDefault();
    openSingle(offer, false, false, slot, true);
  };

  const submitSaveForm = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSentClick(true);
    if ((email && validEmail) || preEmail) {
      setLoading(true);
      const emailParams: QuerySaveEmail = {
        offerid: offer.id,
        email: email || preEmail || "",
        offerLink: offer.trackingUrl,
        companyId: retailerId,
        placementId,
        campaignId: campaignId,
        templateId: settingsTemplate.identifier,
        slot,
        orderId: offer.orderId,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      };

      const response = await postTagEmailOffer(emailParams);
      getTrackingEvent({
        event: "onsendemailclick",
        retailerId,
        campaignId: campaignId,
        offerId: offer.id,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        slot,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });

      if (response?.data?.status) {
        setEmailSent(true);
        setPreEmail && setPreEmail(email || preEmail || "");
      }
    }

    setLoading(false);
  };

  // Validate email on change
  useEffect(() => {
    setSentClick(false);
    setEmailSent(false);
    if (email) {
      const isValid = EMAIL_REGEX.test(email);
      setValidEmail(isValid);
    }
    // eslint-disable-next-line
  }, [email]);

  const handleCopyHtml = () => {
    window.focus();
    navigator.clipboard?.writeText(
      customVoucher ? customVoucher : offer.voucher?.code
    );
    setCopied(true);
    setCopyCode(false);
    setTimeout(() => {
      setCopied(false);
    }, 1000); // Show the message for 1 second
  };

  const handleGetCode = async () => {
    setVoucherLoading(true);
    const customVoucher = await getCustomVoucher({
      offerId: offer.id,
      campaignId: campaign.id,
    });
    setCustomVoucher(customVoucher?.data?.result);
    setGetCode(true);
    setVoucherLoading(false);
  };

  const getVoucherText = () => {
    return customVoucher ? (
      <>
        <span style={{ fontWeight: "400", marginRight: "3px" }}>Code: </span>

        <span style={{ fontWeight: "600" }}>{customVoucher}</span>
      </>
    ) : offer.voucher?.code ? (
      <>
        <span style={{ fontWeight: "400", marginRight: "3px" }}>Code: </span>
        <span style={{ fontWeight: "600" }}>{offer.voucher?.code}</span>
      </>
    ) : (
      <span style={{ fontWeight: "400" }}>Sorry, code just expired!</span>
    );
  };

  return (
    <>
      <div
        className={`feature-item ${offerLength > 3 ? "wide" : ""} ${
          displayOnly && campaign?.campaignGeneralOptions.showAddToOrder
            ? "display"
            : "getbutton"
        }`}
      >
        <input
          className="hidden-input"
          id={offer.id}
          type="checkbox"
          disabled={
            !displayOnly || !campaign?.campaignGeneralOptions.showAddToOrder
          }
          checked={isAdded}
          onChange={handleAdd}
        />

        <span
          style={{
            position: "absolute",
            top: "-8.5px",
            left: "20px",
            fontSize: "10px",
            textTransform: "uppercase",
            color: offer.badge?.fontColor,
            backgroundColor: offer.badge?.backgroundColor,
            padding: "0 6px",
            borderRadius: "3px",
            fontWeight: "600",
            zIndex: 1,
          }}
          className="badge-settings"
        >
          {offer.badge?.name}
        </span>
        <label
          htmlFor={offer.id}
          className="super-item-layout"
          onClick={(e) => handleGetGift(e, offer.trackingUrl)}
          style={{
            backgroundColor: settings?.backgroundColor,
            fontSize: settings?.fontSize,
            color: settings?.textColor,
            border: offer.badge?.useBorder
              ? `1px solid ${offer.badge?.backgroundColor}`
              : "",
            borderBottomColor: !displayOnly
              ? "#d6dae5"
              : offer.badge?.useBorder
              ? `${offer.badge?.backgroundColor}`
              : "",
            borderBottomLeftRadius: displayOnly
              ? `${
                  typeof mainSettings?.borderRadius === "number"
                    ? mainSettings?.borderRadius
                    : 10
                }px`
              : "0",
            borderBottomRightRadius: displayOnly
              ? `${
                  typeof mainSettings?.borderRadius === "number"
                    ? mainSettings?.borderRadius
                    : 10
                }px`
              : "0",
            borderRadius: `${
              typeof mainSettings?.borderRadius === "number"
                ? mainSettings?.borderRadius
                : 10
            }px`,
            position: "relative",
          }}
        >
          <div className="super-item-image">
            <img className={`image-large`} src={imageUrl} alt={offer.title} />
          </div>

          <div className={`super-item-content`}>
            <div className="super-item-content-info">
              <div className="super-item-title">
                <span
                  className={`super-item-title-text max-2lines `}
                  style={{
                    color: settings?.textColor,
                    fontSize: `${Number(settings?.fontSize) + 1}px`,
                  }}
                >
                  {title}
                </span>
              </div>
              <div
                className="super-item-description max-2lines"
                style={{
                  color: settings?.textColor,
                  fontSize: `${settings?.fontSize}px`,
                }}
              >
                {description}
              </div>
            </div>

            <div className="super-item-get">
              <div className="super-item-get-center">
                {displayOnly ? (
                  <>
                    {campaign?.campaignGeneralOptions.showAddToOrder && (
                      <PreSelect
                        offerId={offer.id}
                        buttonFontWeight={settings?.buttonFontWeight}
                        buttonFontSize={settings?.buttonFontSize}
                        showCheckboxSelect={otherSettings?.showCheckboxSelect}
                        checked={isAdded}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {offer?.voucher?.voucherType === VoucherType.CUSTOM &&
                    !getCode ? (
                      <div
                        className="dashed-button-super copy-box"
                        onClick={handleGetCode}
                        style={{
                          fontSize: `${settings?.buttonFontSize}px`,
                          position: "relative",
                          borderRadius: `${mainSettings?.borderRadius}px`,
                        }}
                      >
                        {voucherLoading ? (
                          <svg
                            className="spin"
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                        ) : (
                          "Get code"
                        )}
                      </div>
                    ) : (
                      offer.offerSettings.redeemBy === RedeemBy.webemail && (
                        <div
                          className="super-item-action-get"
                          style={{
                            color: settings?.buttonTextColor,
                            fontSize: `${settings?.buttonFontSize}px`,
                            fontWeight: settings?.buttonFontWeight,
                            backgroundColor: settings?.buttonBackgroundColor,
                            borderRadius: settings?.buttonRounding,
                            border: `1px solid ${
                              settings?.buttonBorderColor ||
                              settings?.buttonBackgroundColor
                            }`,
                          }}
                        >
                          {offer?.offerCTAText || "Get offer now"}
                        </div>
                      )
                    )}
                  </>
                )}
              </div>

              <div className="super-item-get-icons">
                <div className="super-item-info-icon" onClick={handleClickInfo}>
                  {!settings?.icons ||
                  settings?.icons === TemplateIcons.default ? (
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                        fill={settings?.textColor}
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM13.5 18.5C13.5 19.33 12.83 20 12 20C11.17 20 10.5 19.33 10.5 18.5V10.5C10.5 9.67 11.17 9 12 9C12.83 9 13.5 9.67 13.5 10.5V18.5ZM12 7C11.17 7 10.5 6.33 10.5 5.5C10.5 4.67 11.17 4 12 4C12.83 4 13.5 4.67 13.5 5.5C13.5 6.33 12.83 7 12 7Z"
                        fill={settings?.textColor}
                      />
                    </svg>
                  )}
                </div>

                {!displayOnly &&
                  campaign.sharingOptions?.allowSharing &&
                  campaign.sharingOptions.sharingPlatforms?.platforms?.length >
                    0 &&
                  offer?.offerSettings?.allowShare && (
                    <div
                      className="super-item-share-icon super-item-info-icon"
                      onClick={handleClickShare}
                    >
                      <svg
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="share-alt"
                        width="1em"
                        height="1em"
                        fill={settings?.textColor}
                        aria-hidden="true"
                      >
                        <path d="M752 664c-28.5 0-54.8 10-75.4 26.7L469.4 540.8a160.68 160.68 0 000-57.6l207.2-149.9C697.2 350 723.5 360 752 360c66.2 0 120-53.8 120-120s-53.8-120-120-120-120 53.8-120 120c0 11.6 1.6 22.7 4.7 33.3L439.9 415.8C410.7 377.1 364.3 352 312 352c-88.4 0-160 71.6-160 160s71.6 160 160 160c52.3 0 98.7-25.1 127.9-63.8l196.8 142.5c-3.1 10.6-4.7 21.8-4.7 33.3 0 66.2 53.8 120 120 120s120-53.8 120-120-53.8-120-120-120zm0-476c28.7 0 52 23.3 52 52s-23.3 52-52 52-52-23.3-52-52 23.3-52 52-52zM312 600c-48.5 0-88-39.5-88-88s39.5-88 88-88 88 39.5 88 88-39.5 88-88 88zm440 236c-28.7 0-52-23.3-52-52s23.3-52 52-52 52 23.3 52 52-23.3 52-52 52z"></path>
                      </svg>
                    </div>
                  )}
              </div>
            </div>
          </div>

          {!displayOnly &&
            campaign.campaignGeneralOptions.saveOfferOptions
              .allowSavingOffers &&
            campaign.campaignGeneralOptions.saveOfferOptions.allowEmail && (
              <>
                <form
                  className={`non-email-link ${
                    isLoading ? "loading-overlay" : ""
                  }`}
                >
                  <div
                    className="super-item-email non-email-link"
                    style={{
                      border: offer.badge?.useBorder
                        ? `1px solid ${offer.badge?.backgroundColor}`
                        : "",
                      borderRadius: `${mainSettings?.borderRadius}px`,
                      borderTop: "none",
                    }}
                  >
                    {isLoading && (
                      <div className="icon-spin">
                        <svg
                          className="spin"
                          viewBox="0 0 1024 1024"
                          focusable="false"
                          data-icon="loading"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                        </svg>
                      </div>
                    )}
                    <div
                      className="super-item-email-wrapper non-email-link"
                      style={{
                        paddingBottom: hasVoucher ? "15px" : "0",
                      }}
                    >
                      <div
                        className="super-item-email-title non-email-link"
                        style={{ color: settings?.emailTextColor || "#888888" }}
                      >
                        {settings?.emailMeTheGift || "Email me the gift"}
                      </div>
                      <div className="super-item-email-terms non-email-link">
                        Your data will be processed according to BrandSwap's
                        <a
                          href="https://brandswap.com/privacy/"
                          rel="noopener noreferrer"
                          target="_blank"
                          className="non-email-link"
                          style={{ marginLeft: 5 }}
                        >
                          {" "}
                          Privacy Policy
                        </a>
                      </div>
                      <div className="super-item-email-terms non-email-link">
                        <label
                          htmlFor={`accept-email-${offer.id}`}
                          className="non-email-link"
                          style={{
                            color: settings?.emailTextColor || "#888888",
                          }}
                        >
                          <input
                            id={`accept-email-${offer.id}`}
                            type="checkbox"
                            checked={emailSwitch}
                            onChange={(e) => setEmailSwitch(e.target.checked)}
                            className="checkbox-custom non-email-link"
                          />
                          <div className="checkboxsvg non-email-link"></div>I
                          agree to share my email address with BrandSwap to
                          receive details about this offer
                        </label>
                      </div>
                    </div>

                    <div className="super-item-email-input-wrapper">
                      <div className="super-item-email-input non-email-link">
                        <input
                          type="text"
                          className="non-email-link"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailSent(false);
                            setEmailPopulate(false);
                          }}
                          value={
                            emailPopulate
                              ? preEmail || email || ""
                              : email || ""
                          }
                          placeholder={settings?.enterEmail || "Enter E-mail"}
                          style={{
                            borderRadius: `${settings?.emailButtonRounding}px`,
                          }}
                        />
                        <button
                          className="button non-email-link"
                          style={{
                            color: settings?.emailButtonTextColor || "#ffffff",
                            backgroundColor:
                              settings?.emailButtonBackgroundColor || "#888888",
                            borderRadius: `${settings?.emailButtonRounding}px`,
                            borderColor:
                              settings?.emailButtonBorderColor ||
                              settings?.emailButtonBackgroundColor ||
                              "#888888",
                          }}
                          onClick={submitSaveForm}
                          disabled={
                            preEmail && emailPopulate
                              ? !emailSwitch
                              : !emailSwitch
                          }
                        >
                          {settings?.emailSend || "Send"}
                          {emailSent && !isLoading && (
                            <svg
                              width="12"
                              height="9"
                              viewBox="0 0 12 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                marginLeft: "5px",
                              }}
                            >
                              <path
                                d="M11.5667 0.28139C11.9134 0.644571 11.9 1.22002 11.5368 1.56669L4.87009 7.93033C4.51879 8.26565 3.96597 8.26565 3.61468 7.93032L0.281383 4.7485C-0.0817948 4.40183 -0.095174 3.82638 0.2515 3.4632C0.598174 3.10002 1.17362 3.08664 1.5368 3.43332L4.24239 6.01596L10.2814 0.251494C10.6446 -0.0951766 11.22 -0.0817918 11.5667 0.28139Z"
                                fill={
                                  settings?.emailButtonTextColor || "#ffffff"
                                }
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                      {emailSent &&
                        offer.offerSettings.redeemBy === RedeemBy.webemail && (
                          <div className="label-wrapper">
                            <span className="label-success"> Email sent</span>
                          </div>
                        )}
                      {!validEmail && email && sentClick && (
                        <div className="label-wrapper">
                          <span className="label-error">
                            Must be valid email!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </>
            )}
          {offer.voucher?.voucherType && (
            <span
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                fontSize: "11px",
                color: settings?.buttonTextColor,
                backgroundColor: settings?.buttonBackgroundColor,
                fontWeight: "600",
                textAlign: "center",
                width: "100%",
                height: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: offer.badge?.useBorder
                  ? `1px solid ${offer.badge?.backgroundColor}`
                  : "",
                borderTop: "none",
                borderBottomLeftRadius: displayOnly
                  ? `${
                      typeof mainSettings?.borderRadius === "number"
                        ? mainSettings?.borderRadius
                        : 10
                    }px`
                  : "10px",
                borderBottomRightRadius: displayOnly
                  ? `${
                      typeof mainSettings?.borderRadius === "number"
                        ? mainSettings?.borderRadius
                        : 10
                    }px`
                  : "10px",
              }}
              onMouseEnter={() => setCopyCode(true)}
              onMouseLeave={() => {
                setCopyCode(false);
              }}
              onClick={() => handleCopyHtml()}
            >
              {offer.voucher?.voucherType === VoucherType.CUSTOM && !getCode ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                  className="copy-box"
                >
                  Code:
                  <svg
                    style={{
                      marginLeft: "5px",
                    }}
                    width="70"
                    height="16"
                    viewBox="0 0 70 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="70" height="16" fill="" />
                    <circle
                      cx="4"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="10"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="52"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="58"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="16"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="22"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="28"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="34"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="40"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                    <circle
                      cx="46"
                      cy="8"
                      r="2"
                      fill={settings?.buttonTextColor || "white"}
                    />
                  </svg>
                </div>
              ) : (
                <>
                  {copyCode ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        fontWeight: "400",
                        cursor: "pointer",
                      }}
                      onClick={handleCopyHtml}
                      className="copy-box"
                    >
                      {copied ? (
                        <>
                          <svg
                            width="8"
                            height="6"
                            viewBox="0 0 8 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: "5px",
                            }}
                          >
                            <path
                              d="M1 3L3 5L7 1"
                              stroke={settings?.buttonTextColor || "white"}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Copied successfully
                        </>
                      ) : (
                        <>
                          <svg
                            width="11"
                            height="10"
                            viewBox="0 0 11 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              marginRight: "5px",
                            }}
                          >
                            <path
                              d="M7.75 3V2C7.75 1.73478 7.63147 1.48043 7.42049 1.29289C7.20952 1.10536 6.92337 1 6.625 1H2.125C1.82663 1 1.54048 1.10536 1.3295 1.29289C1.11853 1.48043 1 1.73478 1 2V6C1 6.26522 1.11853 6.51957 1.3295 6.70711C1.54048 6.89464 1.82663 7 2.125 7H3.25M4.375 3H8.875C9.49632 3 10 3.44772 10 4V8C10 8.55228 9.49632 9 8.875 9H4.375C3.75368 9 3.25 8.55228 3.25 8V4C3.25 3.44772 3.75368 3 4.375 3Z"
                              stroke={settings?.buttonTextColor || "white"}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Copy Code
                        </>
                      )}
                    </div>
                  ) : copied ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        fontWeight: "400",
                      }}
                      className="copy-box"
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        <path
                          d="M1 3L3 5L7 1"
                          stroke={settings?.buttonTextColor || "white"}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Copied successfully
                    </div>
                  ) : (
                    getVoucherText()
                  )}
                </>
              )}
            </span>
          )}
        </label>
      </div>
    </>
  );
};

export default OfferFeatureItem;
