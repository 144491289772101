import "./css/style.scss";
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  ButtonShapes,
  CampaignType,
  Offer,
  LayoutClass,
  QueryParams,
  SelectedOfferStorage,
  TemplateOptionSettings,
  Template,
  PlacementStage,
} from "../../../types/global.types";
import { getTrackingEvent } from "../../../services/offers/offers.service";
import SwiperComponent from "./swiper/Swiper.component";
import TemplateSingle from "../single/TemplateSingle.component";
import PreCountAndSelectAll from "../components/PreCountAndSelectAll/PreCountAndSelectAll";
import OfferFeatureItem from "./offer-featured-item/OfferFeaturedItem.component";

interface Props {
  params: QueryParams;
  settings: TemplateOptionSettings;
  settingsTemplate: Template;
  campaignId: string;
  placementId: string;
  placementName: string;
  first: boolean;
  defaultOpen: boolean;
  campaignRes: CampaignType;
  type: LayoutClass;
  setOpen: (open: boolean) => void;
  selectedOffers: string[];
  email: string | undefined;
  buttonShape: ButtonShapes;
  slot: number;
}

const PreviewPopupCompact = ({
  params,
  email,
  settings,
  campaignId,
  first,
  campaignRes,
  placementId,
  placementName,
  defaultOpen,
  setOpen,
  selectedOffers,
  buttonShape,
  settingsTemplate,
  slot,
}: Props) => {
  const [campaign, setCampaign] = useState<CampaignType | undefined>(
    campaignRes
  );
  const [preEmail, setPreEmail] = useState<string>(email || "");
  const [single, setSingle] = useState<Offer | undefined>();
  const [isSingleSave, setSingleSave] = useState<boolean>(false);
  const [isSingleShare, setSingleShare] = useState<boolean>(false);
  const [displayOnly] = useState<boolean>(
    campaignRes?.placementSettings[0]?.displayOnly
  );
  const [hasOpen, setHasOpen] = useState<boolean>(!first);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [offerHostType, setOfferHostType] = useState<string>("all");
  const [offerFullList, setOfferFullList] = useState<Offer[]>(
    campaignRes.offers
  );
  const [offerFilteredList, setOfferFilteredList] = useState<Offer[]>(
    campaignRes.offers
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const ref = useRef<HTMLDivElement | null>(null);
  const tagRef = useRef<HTMLDivElement | null>(null);

  const handleCloseFilters = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof Node && !ref.current?.contains(event.target)) {
      setShowFilters(false);
    }
  };

  const handleCloseTag = (event: React.MouseEvent<HTMLElement>) => {
    if (
      event.target instanceof Node &&
      !tagRef.current?.contains(event.target)
    ) {
      handleClose();
    }
  };

  const handleSetFiltersDefault = () => {
    setOfferHostType("all");
    setShowFilters(false);
    setOfferFilteredList(campaignRes.offers);
  };

  // Update offers when campaign changes
  useEffect(() => {
    setOfferFullList(campaignRes.offers);
    setOfferFilteredList(campaignRes.offers);
    setCampaign(campaignRes);
  }, [campaignRes]);

  const offersCategories = useMemo(() => {
    const cats: string[] = [];

    offerFullList.forEach((o) => {
      o.categories.forEach((cat) => {
        !cats.includes(cat.name) && cats.push(cat.name);
      });
    });

    return cats;
    // eslint-disable-next-line
  }, [offerFullList]);

  useEffect(() => {
    const _offerFilteredList: Offer[] = [];
    const _offerFullList: Offer[] = [];
    campaignRes.offers.forEach((o) => {
      if (offerHostType) {
        setSelectedCategory("");
        if (
          (!o.offerSettings.isB2C && offerHostType === "trade") ||
          (o.offerSettings.isB2C && offerHostType === "personal") ||
          offerHostType === "all"
        ) {
          _offerFullList.push(o);
          _offerFilteredList.push(o);
        }
        setOfferFilteredList(_offerFilteredList);
        setOfferFullList(_offerFullList);
      }
    });
    // eslint-disable-next-line
  }, [offerHostType]);

  useEffect(() => {
    const _offerFilteredList: Offer[] = [];
    offerFullList.forEach((o) => {
      if (selectedCategory) {
        o.categories.forEach((cat) => {
          cat.name === selectedCategory && _offerFilteredList.push(o);
        });
      } else {
        _offerFilteredList.push(o);
      }
      setOfferFilteredList(_offerFilteredList);
    });
    // eslint-disable-next-line
  }, [selectedCategory]);

  const featuredOffer = useMemo(() => {
    let _offer: Offer | undefined = offerFilteredList.find(
      (o) => o.slot === 0 && settings.mainLayout.showFeatured
    );
    return _offer;
  }, [offerFilteredList, settings]);

  const handleClose = () => {
    window.top?.postMessage({ event: "closeMainIframe", load: "" }, "*");
    setSingle(undefined);
    setOpen(false);
    campaign &&
      getTrackingEvent({
        event: "ontemplateclose",
        retailerId: campaign.company_Id,
        campaignId,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });
    setCampaign(undefined);
  };

  const handleCloseSingle = () => {
    setSingle(undefined);
    setSingleSave(false);
  };

  const checkSelectedOffers = (
    selectedOffers: string[] = [],
    _campaign: CampaignType
  ) => {
    const newOffers: Offer[] = _campaign.offers.map((offer: Offer) => {
      if (selectedOffers.includes(offer.id)) {
        offer.isAdded = true;
      }
      return offer;
    });
    setCampaign(
      (prevState) => prevState && { ...prevState, offers: newOffers }
    );
  };

  const openSingle = (
    offer: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number,
    isShare?: boolean
  ) => {
    if (isAutoEmail) {
      const params = {
        offer,
        isSave,
        isAuto: true,
        displayOnly,
        campaign: campaign,
        settings,
        companyId: campaign?.company_Id,
        campaignId: campaign?.id,
        placementId,
        placementName,
        slot,
      };
      window.top?.postMessage({ event: "emailPopup", load: params }, "*");
    } else {
      setSingle(offer);
      setSingleSave(isSave);
      setSingleShare(isShare || false);
      window.top?.postMessage({ event: "singleChange", offer, slot }, "*");
    }
  };

  const handleAdd = (offer: Offer, value: boolean, slot: number) => {
    setSelectedOfferStorage({
      id: offer.id,
      link: offer.trackingUrl,
      active: value,
    });
    if (campaign) {
      const newOffers: Offer[] = campaign.offers.map((_offer: Offer) => {
        if (_offer.id === offer.id) {
          _offer.isAdded = value;
        }
        return _offer;
      });
      setCampaign(
        (prevState) => prevState && { ...prevState, offers: newOffers }
      );
    } else {
      const _single = { ...single, isAdded: value };
      setSingle((prevState) => prevState && { ...prevState, isAdded: value });
      window.top?.postMessage(
        { event: "singleChange", offer: _single, slot },
        "*"
      );
    }
    value &&
      campaign &&
      getTrackingEvent({
        event: "onselectoffer",
        offerId: offer.id,
        retailerId: campaign.company_Id,
        campaignId: campaign.id,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        slot,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });
  };

  const handleSelectAll = () => {
    if (!campaign) {
      return;
    }

    const selectedLength = campaign.offers.filter((o) =>
      selectedOffers.includes(o.id)
    ).length;
    const offerLength = campaign?.offers.length;
    const shouldRemove = selectedLength === offerLength;

    campaign.offers.forEach((offer: Offer) => {
      handleAdd(offer, !shouldRemove, offer.slot);
    });
  };

  const setSelectedOfferStorage = (storage: SelectedOfferStorage) => {
    window.top?.postMessage({ event: "setSelectedOffer", load: storage }, "*");
  };

  useEffect(() => {
    if (defaultOpen) {
      setCampaign(campaignRes);
      checkSelectedOffers(selectedOffers, campaignRes);
      if (campaignRes && !single) {
        hasOpen &&
          getTrackingEvent({
            event: "onmainbuttonclick",
            retailerId: campaignRes?.company_Id,
            campaignId,
            placementId,
            placementName,
            tid: settingsTemplate.identifier,
            pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
          });
        getTrackingEvent({
          event: "ontemplateopen",
          retailerId: campaignRes.company_Id,
          campaignId: campaignRes.id,
          placementId,
          placementName,
          tid: settingsTemplate.identifier,
          pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
        });
      }
      setHasOpen(true);
    }
    // eslint-disable-next-line
  }, [defaultOpen]);

  return (
    <>
      {showFilters && settings?.filters?.filters && (
        <div
          className="fm-template-filters-popup-wrapper"
          onClick={handleCloseFilters}
        >
          <div
            className="fm-template-filters-popup"
            ref={ref}
            style={{
              backgroundColor: settings?.filters?.backgroundColor || "#ffffff",
              borderRadius: `${settings?.mainLayout.borderRadius}px`,
            }}
          >
            {!!campaignRes.offers.find((o) => !o.offerSettings.isB2C) &&
              !!campaignRes.offers.find((o) => o.offerSettings.isB2C) && (
                <div
                  className="fm-template-filter-list"
                  style={{
                    borderColor: settings?.filters?.borderColor || "#939393",
                    borderRadius: `${settings?.mainLayout.borderRadius}px`,
                  }}
                >
                  <div
                    className="fm-template-filter-title"
                    style={{
                      color: settings?.filters?.activeColor || "#0053a0",
                    }}
                  >
                    Type
                  </div>
                  <ul
                    style={{
                      color: settings?.filters?.textColor || "#939393",
                    }}
                  >
                    <li onClick={() => setOfferHostType("all")}>
                      <span
                        className="circle"
                        style={{
                          border:
                            offerHostType === "all"
                              ? `3px solid ${
                                  settings?.filters?.activeColor || "#0053a0"
                                }`
                              : `1px solid ${
                                  settings?.filters?.borderColor || "#939393"
                                }`,
                        }}
                      ></span>
                      {settings?.filters?.allOffers || "All"}
                    </li>
                    <li onClick={() => setOfferHostType("personal")}>
                      <span
                        className="circle"
                        style={{
                          border:
                            offerHostType === "personal"
                              ? `3px solid ${
                                  settings?.filters?.activeColor || "#0053a0"
                                }`
                              : `1px solid ${
                                  settings?.filters?.borderColor || "#939393"
                                }`,
                        }}
                      ></span>
                      {settings?.filters?.personalOffers || "Personal offers"}
                    </li>
                    <li onClick={() => setOfferHostType("trade")}>
                      <span
                        className="circle"
                        style={{
                          border:
                            offerHostType === "trade"
                              ? `3px solid ${
                                  settings?.filters?.activeColor || "#0053a0"
                                }`
                              : `1px solid ${
                                  settings?.filters?.borderColor || "#939393"
                                }`,
                        }}
                      ></span>
                      {settings?.filters?.tradeOffers || "Trade offers"}
                    </li>
                  </ul>
                </div>
              )}
            <div
              className="fm-template-filter-list"
              style={{
                borderColor: settings?.filters?.borderColor || "#939393",
                borderRadius: `${settings?.mainLayout.borderRadius}px`,
              }}
            >
              <div
                className="fm-template-filter-title"
                style={{
                  color: settings?.filters?.activeColor || "#0053a0",
                }}
              >
                Industries
              </div>
              <ul
                style={{
                  color: settings?.filters?.textColor || "#939393",
                }}
              >
                {offersCategories.length > 0 && (
                  <li
                    key="all-categories"
                    onClick={() => setSelectedCategory("")}
                  >
                    <span
                      className="circle"
                      style={{
                        border: !selectedCategory
                          ? `3px solid ${
                              settings?.filters?.activeColor || "#0053a0"
                            }`
                          : `1px solid ${
                              settings?.filters?.borderColor || "#939393"
                            }`,
                      }}
                    ></span>
                    All categories
                  </li>
                )}
                {offersCategories.map((category) => {
                  return (
                    <li
                      key={category}
                      onClick={() =>
                        setSelectedCategory(
                          category === selectedCategory ? "" : category
                        )
                      }
                    >
                      <span
                        className="circle"
                        style={{
                          border:
                            category === selectedCategory
                              ? `3px solid ${
                                  settings?.filters?.activeColor || "#0053a0"
                                }`
                              : `1px solid ${
                                  settings?.filters?.borderColor || "#939393"
                                }`,
                        }}
                      ></span>
                      {category}
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="fm-template-filter-buttons">
              <div
                className="fm-template-filter-button"
                style={{
                  backgroundColor:
                    (settings?.filters?.activeColor || "#0053a0") + "20",
                  borderColor: settings?.filters?.activeColor || "#0053a0",
                  color: settings?.filters?.activeColor || "#0053a0",
                  borderRadius: `${settings?.mainLayout.borderRadius}px`,
                }}
                onClick={handleSetFiltersDefault}
              >
                Cancel
              </div>
              <div
                className="fm-template-filter-button"
                style={{
                  backgroundColor: settings?.filters?.activeColor || "#0053a0",
                  borderColor: settings?.filters?.activeColor || "#0053a0",
                  color: settings?.filters?.backgroundColor || "#ffffff",
                  borderRadius: `${settings?.mainLayout.borderRadius}px`,
                }}
                onClick={() => setShowFilters(false)}
              >
                Apply
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`fm-template-wrapper ${
          settings?.location?.location || "center"
        }`}
        onClick={handleCloseTag}
      >
        <div
          className={`fm-template-popup ${
            campaignRes.offers.length < 3
              ? "fm-template-popup-" + campaignRes.offers.length
              : "fm-template-popup-sixpack"
          }`}
          ref={tagRef}
          style={{
            backgroundColor: settings?.mainLayout.backgroundColor,
            borderColor:
              settings?.mainLayout?.borderColor ||
              settings?.mainLayout.backgroundColor,
            transform: `translate(${settings?.location.x || 0}px, ${
              settings?.location.y || 0
            }px)`,
            fontFamily: settings?.mainLayout.fontFamily,
            color: settings?.mainLayout.textColor,
            marginTop: settings?.location.y
              ? settings?.location?.y < 0
                ? `${settings?.location.y * -1}px`
                : `${settings?.location.y}px`
              : "",
            padding: `${
              typeof settings?.mainLayout.paddingTopBottom !== "undefined"
                ? settings?.mainLayout.paddingTopBottom
                : 20
            }px ${
              typeof settings?.mainLayout.paddingLeftRight !== "undefined"
                ? settings?.mainLayout.paddingLeftRight
                : 0
            }px`,
            borderRadius: `${settings?.mainLayout.borderRadius}px`,
          }}
        >
          <div
            className="preview-minimize"
            onClick={() => handleClose()}
            style={{ backgroundColor: settings?.dealLayout.backgroundColor }}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.176625 0.34468C0.385958 0.133389 0.710902 0.109912 0.945926 0.274249L1.02944 0.34468L7.41698 6.79213L13.9706 0.178277C14.2061 -0.0594256 14.5879 -0.0594256 14.8234 0.178277C15.0327 0.389568 15.056 0.717552 14.8932 0.954775L14.8234 1.03907L8.2698 7.65293L14.5195 13.9609C14.755 14.1986 14.755 14.584 14.5195 14.8217C14.3102 15.033 13.9852 15.0565 13.7502 14.8922L13.6667 14.8217L7.41698 8.51372L1.17826 14.8118C0.942765 15.0495 0.560944 15.0495 0.325445 14.8118C0.116112 14.6005 0.0928526 14.2726 0.255667 14.0353L0.325445 13.951L6.56416 7.65293L0.176625 1.20548C-0.0588749 0.967774 -0.0588749 0.582382 0.176625 0.34468Z"
                fill={settings?.dealLayout.textColor}
              />
            </svg>
          </div>

          <div className={`fm-template-title`}>
            {buttonShape === ButtonShapes.squareImage && (
              <>
                <div className="fm-template-title-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.2 10V19H2.8V10M10 19V5.5M10 5.5H5.95C5.35326 5.5 4.78097 5.26295 4.35901 4.84099C3.93705 4.41903 3.7 3.84674 3.7 3.25C3.7 2.65326 3.93705 2.08097 4.35901 1.65901C4.78097 1.23705 5.35326 1 5.95 1C9.1 1 10 5.5 10 5.5ZM10 5.5H14.05C14.6467 5.5 15.219 5.26295 15.641 4.84099C16.0629 4.41903 16.3 3.84674 16.3 3.25C16.3 2.65326 16.0629 2.08097 15.641 1.65901C15.219 1.23705 14.6467 1 14.05 1C10.9 1 10 5.5 10 5.5ZM1 5.5H19V10H1V5.5Z"
                      stroke={settings?.mainLayout.headerTextColor}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            )}

            {buttonShape === ButtonShapes.squareImageLock && (
              <>
                <div className="fm-template-title-icon">
                  {displayOnly ? (
                    <svg
                      width="42"
                      height="47"
                      viewBox="0 0 42 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z"
                        stroke={settings?.mainLayout.headerTextColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="42"
                      height="47"
                      viewBox="0 0 42 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z"
                        stroke={settings?.mainLayout.headerTextColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </>
            )}

            <span
              className={`fm-template-title-label`}
              style={{
                fontSize: settings?.mainLayout.headerFontSize + "px",
                fontWeight: settings?.mainLayout.headerFontWeight,
                color: settings?.mainLayout.headerTextColor,
              }}
            >
              {settings?.mainLayout.headerText || ""}
            </span>
          </div>

          <div
            className={`fm-template-description`}
            style={{
              fontSize: settings?.mainLayout.subHeaderFontSize + "px",
              fontWeight: settings?.mainLayout.subHeaderFontWeight,
              color: settings?.mainLayout.subHeaderTextColor,
            }}
          >
            {settings?.mainLayout.subHeaderText || ""}
          </div>

          {settings?.filters?.filters && (
            <div className="fm-template-filters">
              <div
                className="fm-template-filters-trigger"
                onClick={() => setShowFilters(true)}
                style={{
                  borderColor: settings?.filters?.borderColor || "#939393",
                  backgroundColor:
                    settings?.filters?.backgroundColor || "#e5eef5",
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.9999 1.33301H2.99992C2.07944 1.33301 1.31187 2.09077 1.53896 2.9828C2.02995 4.91144 3.36178 6.50421 5.12113 7.34779C5.80685 7.67658 6.33325 8.32119 6.33325 9.08166V14.1513C6.33325 14.7708 6.98518 15.1737 7.53926 14.8967L9.20593 14.0633C9.48825 13.9222 9.66658 13.6336 9.66658 13.318V9.08166C9.66658 8.32119 10.193 7.67658 10.8787 7.34779C12.6381 6.50421 13.9699 4.91144 14.4609 2.9828C14.688 2.09077 13.9204 1.33301 12.9999 1.33301Z"
                    stroke={settings?.filters?.textColor || "#939393"}
                    strokeWidth="1.33333"
                  />
                </svg>
                <span
                  style={{ color: settings?.filters?.textColor || "#939393" }}
                >
                  {settings?.filters?.filterOffers || "Filter offers"}
                </span>
              </div>
            </div>
          )}

          {displayOnly && campaign?.campaignGeneralOptions?.showAddToOrder && (
            <PreCountAndSelectAll
              offerLength={campaign?.offers.length}
              countText={settings?.otherSettings?.counterText}
              selectText={settings?.otherSettings?.selectedText}
              showCheckboxSelect={
                settings?.otherSettings?.showCheckboxSelect || false
              }
              noOfSelected={
                campaign?.offers.filter((o) => selectedOffers.includes(o.id))
                  .length
              }
              showSelectAll={settings?.otherSettings?.showSelectAll || false}
              handleSelectAll={handleSelectAll}
              highlightColor={settings?.mainLayout?.highlightColor}
              showCount={settings?.otherSettings?.showCounter || false}
              borderColor={settings?.dealLayout?.borderColor}
            />
          )}

          {settings && campaign && (
            <>
              {featuredOffer && (
                <>
                  <div className="fm-template-feature">
                    <OfferFeatureItem
                      slot={0}
                      settingsTemplate={settingsTemplate}
                      otherSettings={settings.otherSettings}
                      campaignId={campaignId}
                      placementId={placementId}
                      placementName={placementName}
                      offer={featuredOffer}
                      offerLength={campaign.offers.length}
                      settings={settings}
                      openSingle={openSingle}
                      onAdd={handleAdd}
                      setPreEmail={setPreEmail}
                      preEmail={preEmail || email}
                      displayOnly={displayOnly}
                      campaign={campaign}
                      isAdded={featuredOffer.isAdded || false}
                      retailerId={campaign.company_Id}
                      hasVoucher={
                        (featuredOffer.voucher &&
                          !!featuredOffer.voucher.voucherType) ||
                        false
                      }
                    />
                  </div>
                </>
              )}

              <div
                className={`fm-template-swiper`}
                style={
                  {
                    "--swiper-pagination-color":
                      settings?.dealLayout.buttonBackgroundColor,
                    "--swiper-pagination-bullet-inactive-color": `${settings?.dealLayout.buttonBackgroundColor}e0`,
                  } as CSSProperties
                }
              >
                {offerFilteredList.length > 0 && campaign && settings && (
                  <SwiperComponent
                    offersCount={3}
                    offers={offerFilteredList.filter(
                      (f) => f.id !== featuredOffer?.id
                    )}
                    settings={settings}
                    campaign={campaign}
                    defaultOpen={defaultOpen}
                    settingsTemplate={settingsTemplate}
                    displayOnly={displayOnly}
                    campaignId={campaignId}
                    placementId={placementId}
                    placementName={placementName}
                    openSingle={openSingle}
                    onAdd={handleAdd}
                  ></SwiperComponent>
                )}
              </div>
            </>
          )}

          <div className="fm-template-footer">
            {displayOnly ? (
              <>
                {campaign?.campaignGeneralOptions.emailCaptureOnOffers && (
                  <>
                    <span>
                      By selecting you will receive by e-mail after purchase
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                <span>
                  By selecting the option above you will be directed to a third
                  party website. Any use of third party websites are not subject
                  to our Privacy Policy, and we encourage you to also read their
                  privacy statements.
                </span>
              </>
            )}
          </div>
        </div>
      </div>

      {single && campaign?.id && (
        <TemplateSingle
          slot={slot}
          settings={settings}
          isInline={false}
          offer={single}
          isSave={isSingleSave}
          isShare={isSingleShare}
          placementId={placementId}
          placementName={placementName}
          settingsTemplate={settingsTemplate}
          campaignObj={campaign}
          displayOnly={displayOnly}
          campaignId={campaign?.id}
          companyId={campaign.company_Id}
          updateCampaign={handleAdd}
          setPreEmail={setPreEmail}
          preEmail={preEmail || email}
          closeSignle={handleCloseSingle}
        />
      )}
    </>
  );
};

export default PreviewPopupCompact;
